// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
	if($("#calendar-view").length){
	Date.prototype.isLeapYear = function() {
	  var year = this.getFullYear();
	  if((year & 3) != 0) return false;
	  return ((year % 100) != 0 || (year % 400) == 0);
	};
	//alert(new Date(2016,1,1).isLeapYear());
	
	Date.prototype.dayOfYear= function() {
	  // see http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
	  var j1= new Date(this);
	  j1.setMonth(0, 0);
	  return Math.round((this-j1)/8.64e7);
	};
	//alert(new Date(1968,7,3).dayOfYear())
	
	Date.prototype.getWeekNumber = function() {
	  // see http://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
	  var d = new Date(+this);
	  d.setHours(0,0,0,0);
	  d.setDate(d.getDate()+4-(d.getDay()||7));
	  return Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);
	};
	//alert(new Date().getWeekNumber())
	
	/*
	Date.prototype.easterDay = function() {
	  var Y = this.getFullYear();
	  //return easterForYear(Y);
	  return Easter(Y);
	}
	alert(new Date(2017,1,2).easterDay());
	
	// see: http://techneilogy.blogspot.fr/2012/02/couple-of-years-ago-i-posted-source.html
	function easterForYear (year) {
	// i.e "Dimanche de Pâques" in french
	  var a = year % 19;
	  var b = Math.floor(year / 100);
	  var c = year % 100;
	  var d = Math.floor(b / 4); 
	  var e = b % 4;
	  var f = Math.floor((b + 8) / 25);
	  var g = Math.floor((b - f + 1) / 3); 
	  var h = (19 * a + b - d - g + 15) % 30;
	  var i = Math.floor(c / 4);
	  var k = c % 4;
	  var l = (32 + 2 * e + 2 * i - h - k) % 7;
	  var m = Math.floor((a + 11 * h + 22 * l) / 451);
	  var n0 = (h + l + 7 * m + 114)
	  var n = Math.floor(n0 / 31) - 1;
	  var p = n0 % 31 + 1;
	  var date = new Date(year,n,p);
	  return date; // Sun Apr 16 2017 00:00:00 GMT+0200 (CEST)
	}
	// alternative: http://stackoverflow.com/questions/1284314/easter-date-in-javascript
	function Easter(Y) {
		var C = Math.floor(Y/100);
		var N = Y - 19*Math.floor(Y/19);
		var K = Math.floor((C - 17)/25);
		var I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
		I = I - 30*Math.floor((I/30));
		I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
		var J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
		J = J - 7*Math.floor(J/7);
		var L = I - J;
		var M = 3 + Math.floor((L + 40)/44);
		var D = L + 28 - 31*Math.floor(M/4);
		return padout(M) + '.' + padout(D); // 04.16
	}
	function padout(number) { return (number < 10) ? '0' + number : number; }
	*/
	
	// see http://cwestblog.com/2012/09/28/javascript-number-getordinalfor/
	(function(o) {
	  Number.getEnglishOrdinalFor = function(intNum, includeNumber) {
		return (includeNumber ? intNum : "")
		  + (o[((intNum = Math.abs(intNum % 100)) - 20) % 10] || o[intNum] || "th");
	  };
	})([,"st","nd","rd"]);
	
	/*
	  Inspiration: http://jszen.blogspot.fr/2007/03/how-to-build-simple-calendar-with.html
	  Modifications for: a more semantic & accessible calendar AND Bootstrap 4 use.
	  see: https://www.sitepoint.com/html5-time-element-guide/
	  
	  Important note: here we use Monday as first day of week (ISO standard), 
	  when javascript use Sunday.
	  
	  “And on the seventh day God finished his work which he had made; and he rested on the seventh day from all his work which he had made…”
	*/
	
	// these are labels for the days of the week
	var cal_days_labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	/* if you want use sunday as first day of week:
	var cal_days_labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];*/
	
	
	// these are human-readable month name labels, in order
	var cal_months_labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	
	// these are the days of the week for each month, in order
	var cal_days_in_month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	
	// this is the current date
	var cal_current_date = new Date();
	// this is the current day number
	var cal_current_day_number = cal_current_date.dayOfYear();
	// this is the current week number
	var cal_current_week_number = cal_current_date.getWeekNumber();
	
	function Calendar(month, year) {
	  // if month and year aren't passed thru the function, we use the current date
	  this.month = (isNaN(month) || month === null) ? cal_current_date.getMonth() : month;
	  this.year = (isNaN(year) || year === null) ? cal_current_date.getFullYear() : year;
	  this.html = '';
	}
	
	Calendar.prototype.generateHTML = function() {
	  // get first day of month
	  var firstDay = new Date(this.year, this.month, 1);
	  var startingDay = firstDay.getDay();
	
	  /* if you want sunday as first day of week avoid this 'if else' statement */
	  if (startingDay == 0 ) {
		startingDay = 6
	  }
	  else startingDay--;
	  
	  // find number of days in month
	  var monthLength = cal_days_in_month[this.month];
	  // compensate for leap year
	  if (this.month == 1) { // February only!
		if ( new Date(this.year,1,1).isLeapYear() ) {
		  monthLength = 29;
		}
	  };
	  //console.log(this.year + '/' + this.month+1 + ' have ' + monthLength + ' days. Is leap Year? ',new Date(this.year,1,1).isLeapYear());
	  var MM = parseInt(this.month+1);
	  MM = (MM < 10) ? '0'+MM : MM;
	  var YYYY_MM = this.year+'-'+MM; 
	  var html = '';
	  html += '<form id="calendar" class="" itemscope itemtype="http://schema.org/Table">';
	  html += '<table id="cal-'+YYYY_MM+'" class="calendar" cellspacing="0" cellpadding="0">';
	  
	  // do the caption
	  html += '<caption itemprop="about">';
	  // do the period selector
	  var selected = '';
	  var monthName = cal_months_labels[this.month]; 
	  html += '<header class="d-flex flex-column">';
	  //html += '<div class="row align-self-center sr-only"><h5>Calendar of&nbsp; <small><time datetime="'+YYYY_MM+'" aria-current="true">'+monthName+' '+this.year+'</time></small></h5></div>';
	  var prevMonth = parseInt(this.month)-1;
	  var nextMonth = parseInt(this.month)+1;
	  var prevMonthYear = this.year;
	  var nextMonthYear = this.year;
	  if (parseInt(this.month) == 0) {
		prevMonth = 11;
		prevMonthYear = parseInt(this.year) - 1;
	  }
	  if (parseInt(this.month) == 11) {
		nextMonth = 0;
		nextMonthYear = parseInt(this.year) + 1;
	  }
	  
	  html += '<nav id="nav-mounths" class="navbar">';
	  html += '<button accesskey="k" id="skipPrevMonth" name="skipPrevMonth" type="button" class="col-2 flex-first btn btn-sm skip changeMonth " value="'+prevMonth+','+prevMonthYear+'" data-placement="right"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="19.999" viewBox="0 0 25 19.999"><path id="Pfad_64" data-name="Pfad 64" d="M15.1,8.783a.957.957,0,0,0-1.36,0L4.781,17.816a.972.972,0,0,0,0,1.367l8.958,9.032a.957.957,0,0,0,1.36,0,.972.972,0,0,0,0-1.369L7.781,19.469l20.756.015a.968.968,0,0,0,0-1.935L7.779,17.533,15.1,10.151A.972.972,0,0,0,15.1,8.783Z" transform="translate(-4.5 -8.5)" fill="#00b085" fill-rule="evenodd"/></svg></button>';
	  html += monthName;
	  html += ' ';
	  html += this.year;
	  html += '<button accesskey="l" id="skipNextMonth" name="skipNextMonth" type="button" class="col-2 flex-last btn btn-sm changeMonth skip" value="'+nextMonth+','+nextMonthYear+'" data-placement="left"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="19.999" viewBox="0 0 25 19.999"><path id="Pfad_64" data-name="Pfad 64" d="M18.9,8.783a.957.957,0,0,1,1.36,0l8.958,9.032a.972.972,0,0,1,0,1.367l-8.958,9.032a.957.957,0,0,1-1.36,0,.972.972,0,0,1,0-1.369l7.319-7.379-20.756.015a.968.968,0,0,1,0-1.935l20.761-.015L18.9,10.151A.972.972,0,0,1,18.9,8.783Z" transform="translate(-4.5 -8.5)" fill="#00b085" fill-rule="evenodd"/></svg></button>';
	  html += '</nav>';
	
	  var prevYear = parseInt(this.year)-1;
	  var nextYear = parseInt(this.year)+1;
	
	  html += '<fiedlset class="no-js-submit row align-self-center">';
	  html += '<div class="col justify-content-center">';
	  html += '<button id="changeMonth" name="changeMonth" type="submit" class="btn changeMonth" value="'+this.month+','+this.year+'"><i class="fa"></i><span class="no-js-submit"> Change Month<span></button>';
	  html += '</div>';
	  html += '</fieldset>';
	
	  html += '</header>';
	  html += '</caption>';
	  
	  html += '<thead>';
	  html += '<tr class="no-event row row_0">';
	  html += '<th class="cell col_0" scope="row" hidden aria-hidden="true">Weeks of ' + monthName + '</th>';
	  for (var d = 0; d <= 6; d++) {
		var day_name = cal_days_labels[d];
		var day_abbr = day_name.charAt(0) + day_name.charAt(1) + day_name.charAt(2);
		html += '<th class="cell col_'+parseInt(d+1)+' '+day_name.toLowerCase()+'" scope="col">';
		html += '<abbr class="cell-content" title="'+day_name+'">';
		html += day_abbr;
		html += '</abbr>';
		html += '</th>';
	  }
	  html += '</tr>';
	  html += '</thead>';
	
	  html += '<tbody>'; // open the month rows
	  // fill in the days:
	  var day = 1;
	  var row = 1;
	  html += '<tr class="row row_'+row+'">'; // open the first week row of the month
	  // this loop is for is weeks (rows)
	  for (var i = 0; i < 7; i++) {
		// TODO: if first day of week is sunday (like in US & UK) calc getWeekNumber() for following Monday
		var thisWeekNumber = new Date(this.year,this.month,day).getWeekNumber();
		var isoWeekNumber = thisWeekNumber;
		if (isoWeekNumber < 10) isoWeekNumber = '0'+isoWeekNumber;
		if ( (thisWeekNumber == 52) & (this.month == 0) ) {
		  var YYYY_WW = parseInt(this.year-1)+'W'+isoWeekNumber;
		  var content = Number.getEnglishOrdinalFor(thisWeekNumber, true) + ' week of ' + parseInt(this.year-1);
		}
		else {
		  var YYYY_WW = this.year+'W'+isoWeekNumber;
		  var content = Number.getEnglishOrdinalFor(thisWeekNumber, true) + ' week of ' + this.year;
		}
		html += '<th id=week-'+YYYY_WW+' class="cell col_0" data-week-number="'+thisWeekNumber+'" scope="row" hidden aria-hidden="false">';
		html += '<time class="cell-content '+YYYY_WW+'" datetime="'+YYYY_WW+'">' + content + '</time>';
		html += '</th>';
		// this loop is for weekdays (cells)
		for (var j = 0; j <= 6; j++) {
		  day_name = cal_days_labels[j];
		  var col = 'col_'+parseInt(j+1);
		  if (day <= monthLength && (i > 0 || j >= startingDay)) {
			var YYYY_MM_DD = '';
			if (day < 10) YYYY_MM_DD = YYYY_MM+'-0'+day;
			else YYYY_MM_DD = YYYY_MM+'-'+day;
			var thisDayNumber = new Date(this.year,this.month,day).dayOfYear();
			if (this.year == cal_current_date.getFullYear()) {
			  var timeClass = 'text-primary today';
			  var ariaCurrent = ' aria-current="date"';
			  // TODO Wrong: correct classes for actual month only
			  if (thisDayNumber < cal_current_day_number) {
				  timeClass = 'past';
				  ariaCurrent = '';
			  }
			  if (thisDayNumber > cal_current_day_number) {
				  timeClass = 'future';
				  ariaCurrent = '';
			  } 
			}
			else {
			  if (this.year < cal_current_date.getFullYear()) timeClass = 'past';
			  else timeClass = 'future';
			  ariaCurrent = '';
			}
			html += '<td id="day-'+YYYY_MM_DD+'" class="'+day_name.toLowerCase()+' cell '+col+'" data-day-number="'+thisDayNumber+'"'+ariaCurrent+'>';
			html += '<time class="cell-content '+timeClass+' '+YYYY_MM_DD+'" datetime="'+YYYY_MM_DD+'">'+day+'</time>';
			day++;
		  }
		  else {
			html += '<td class="cell '+col+' empty-cell">';
		  }
		  html += '</td>'; // close the cell day
		}
		
		if (day > monthLength) {
		  break; // stop making rows if we've run out of days
		} else {
		  html += '</tr>'; // close the week row
		  row++;
		  html += '<tr class="row row_'+row+'">';  // open a new week row
		}
	  }
	  html += '</tr>'; // close the last week row of the month
	  //make empty row to ensure stable positioning (top nav buttons in particular)
	  if (row >= 4) {
		for (var i = 1; i<=(6-row); i++) {
		  html += '<tr class="row row_'+parseInt(row+i)+' empty-row"><th class="cell col_0" scope="row" hidden aria-hidden="true"></th>';
		  for (var j = 1; j <= 7; j++) {
			html += '<td class="cell col_'+j+'empty-cell">&nbsp;</td>';
		  }
		  html += '</td></tr>';
		}
	  }
	  html += '</tbody>'; // close the month rows
	  
	  html += '</table>';
	  html += '</form>'; // close the calendar table
	  this.html = html;
	};
	
	Calendar.prototype.getHTML = function() {
	  return this.html;
	};
	
	function draw(period) {
	  if (period=='') {
		var cal = new Calendar()
	  }
	  else {
		var data = period.split(',');
		var cal = new Calendar(parseInt(data[0]),parseInt(data[1]));    
	  }
	  cal.generateHTML();
	  var calendar = document.getElementById("calendar-view");
	  calendar.innerHTML = cal.getHTML();
	  $('.changeMonth').on('click', function() {
		//alert($(this).val());
		draw($(this).val());
	  });
	  $('.custom-select').on('change', function() {
		$('#calendar .changeMonth').attr('value',$('#selectorMonth').val()+','+$('#selectorYear').val());
		draw($('#calendar .changeMonth').val());
	  });
	  // tooltips activation (Bootstrap 4)
	  $('.jump-select').hover(
		function() {
		  $( this ).tooltip('show');
		}, function() {
		  $( this ).tooltip('hide');
		}
	  );
	  $('.skip').click(
		function() {
		  $( this ).tooltip('hide');
		}
	  );    
	  $('.skip').hover(
		function() {
		  $( this ).tooltip('show');
		}
	  );
	  // $('#changeMonth').attr('disabled','disabled');  ;-)
	  $('#changeMonth').attr('aria-disabled','true'); // ;-)
	  //$('.calendar thead abbr').tooltip();
	  $('html:not(.no-js) #changeMonth').attr('disabled','disabled'); // ;-)
	  $('.no-js-submit').attr('hidden','hidden');
	}
	
	$(document).ready(function(){
	  if ($('html').hasClass('no-js')) $('html').removeClass('no-js').addClass('js');
	  draw('');
	});
	
}
}); 